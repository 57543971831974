import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import './App.css';
import ArticleLayout from './components/articles/ArticleLayout';
import Homepage from './pages/homepage/HomePage';

class App extends Component {

  render() {
    return (
         
      <div className="App container">
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/article/:article_key" element={<ArticleLayout />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
