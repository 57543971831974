import React, { Component } from 'react';
import { Card, Badge, Button, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default class ArticleCard extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.getCardTags = this.getCardTags.bind(this)
    }


    getCardTags() {
        return this.props.tags.map((tag, index) => {
            return <Badge bg="primary" style={{ margin: '0.1%' }} key={index}>
                {tag}
            </Badge>
        })

    }

    render() {
        let article_link = "/article/" + this.props.article_key
        return (
            <div>
                <Card
                    style={{ margin: '1%', textAlign: 'left' }}
                >
                    <Card.Body style={{ padding: '1%' }}>
                        <Card.Title style={{ fontSize: '1.2em' }}><Link to={article_link}>{this.props.title}</Link></Card.Title>
                        <Card.Text>{this.props.summary}</Card.Text>
                        <Row style={{padding:'1%'}}>
                            <Col md={{ span: 12, offset: 10 }}>
                                <Link to={article_link}>
                                    <Button variant="secondary"  >Read more</Button>
                                </Link>
                            </Col>
                        </Row>
                        <Card.Footer>{this.getCardTags()}</Card.Footer>
                    </Card.Body>
                </Card>


            </div>
        );
    }
}
