import React from 'react';
import { Card, Badge } from 'react-bootstrap';
import articleMetaMap from '../../resources/article_meta.json'
import NavigationBar from '../NavigationBar';
import ReactMarkdown from 'react-markdown';
import { useState, useEffect  } from 'react';

import {
    useParams,
} from "react-router-dom";



let content_map = {
    "netflix_pasword_sharing_struggle": require('../../resources/articles/netflix_password_sharing_struggle.md'),
    "Say_Goodbye_to_OTP_mismatch":  require('../../resources/articles/Say_Goodbye_to_OTP_mismatch.md'),
    "New_Whatsapp_Scam_Alert":  require('../../resources/articles/New_Whatsapp_Scam_Alert.md'),
    "Revamping_User_Experience:_Suggestions_for_PhonePe,_Google_Pay,_and_Paytm":  require('../../resources/articles/Revamping_User_Experience:_Suggestions_for_PhonePe,_Google_Pay,_and_Paytm.md'),
    "Android_Security_Gap:_A_Potential_Threat_to_User_Safety":  require('../../resources/articles/Android_Security_Gap:_A_Potential_Threat_to_User_Safety.md'),
    "Mutual_Fund_Platforms_1":  require('../../resources/articles/Mutual_Fund_Platforms_1.md')
}


function getCardTags(tags) {
    return tags.map((tag, index) => {
        return <Badge bg="primary" style={{ margin: '0.1%' }} key={index}>
            {tag}
        </Badge>
    })

}

export default function ArticleLayout() {
   const [markdown, setMarkDown] = useState("")
   let {article_key } = useParams();
   var article_meta = articleMetaMap[article_key]

    useEffect(()=>{
        fetch(content_map[article_key])
        .then(res => res.text())
        .then(text => {
            setMarkDown(text)

        });
    },[article_key])

    
    return (
        <div>
            <NavigationBar />
            <Card
                style={{ margin: '1%', textAlign: 'left' }}
            >
                <Card.Body style={{ padding: '1%' }}>
                    <Card.Title style={{ fontSize: '1.2em' }}>
                        <h2>{article_meta.title}</h2>                    
                    </Card.Title>
                    <Card.Text><ReactMarkdown children={markdown}/></Card.Text>
                    <Card.Footer>{getCardTags(article_meta.tags)}</Card.Footer>

                </Card.Body>
            </Card>

           


        </div>
    );
}

