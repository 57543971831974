import React, { Component } from 'react';
import ArticleCardController from '../../components/articles/ArticleCardController'
import NavigationBar from '../../components/NavigationBar';

export default class Homepage extends Component {
    
    render() {
        return (
            <div>
                <NavigationBar />
                <ArticleCardController/>
                <div>
                </div>
            </div>
        );
    }
}