import React, { Component } from 'react';
import {Container, Navbar}  from 'react-bootstrap';

export default class NavigationBar extends Component {

    render() {
        return (
            <div>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href={process.env.PUBLIC_URL + "/"}>wdydt : Why do you do this !!</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    </Container>
                </Navbar>
            </div>
        );
    }
}
