import React, { Component } from 'react';
import ArticleCard from './ArticleCard';
import articleList from '../../resources/article_list.json';
import articleMetaMap from '../../resources/article_meta.json'

export default class ArticleCardController extends Component {
    constructor(props) {
        super(props);
        this.getArticleCards = this.getArticleCards.bind(this);
    }


    getArticleCards() {
        return articleList.map((article_key, index) => {
            var article_meta = articleMetaMap[article_key]
            return <div key ={index}>
                <ArticleCard article_key={article_key} title={article_meta.title} summary={article_meta.summary} tags={article_meta.tags} />
            </div>
        })
    }

    render() {
        return (
            <div>
                {this.getArticleCards()}
            </div>
        );
    }
}
